@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #333
}

h1 {
  font-size: 30px;
}

p {
  font-size: 1em;
}

header {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  background-color: #0066cc;
  color: #fff;
}

main {
  padding: 50px;
  min-height: 55vh;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 50px;
  background-color: #f3f2f1;
}

footer .info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  font-weight: 700;
  font-size: 1.25em;
}

footer .copy {
  width: 100%;
  text-align: center;
  padding-top: 25px;
  border-top: solid 1px #ccc;
}

.converter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clean-data, .complete-data {
  width: 48%;
  margin: 25px 0;
}

.converter .clean-data label, .converter .complete-data label {
  display: block;
  margin: 15px 0;
  font-weight: 500;
}

.browse-file{
  width: 35%;
  background-color: #366992;
  color: white;
  border: none;
  cursor: pointer;
}

.remove {
  width: 20%;
  background-color: #db2222;
  color: white;
  border: none;
  cursor: pointer;
}

.browse-file, .remove{
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.data-container p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.data-container .btn-copy{
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  background-color: #369257;
  color: #fff;
  border: none;
  margin-bottom: 25px;
  border-radius: 5px;
}

.data-container .reload{
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  background-color: #db2222;
  color: #fff;
  border: none;
  margin: 0 0 25px 25px;
  border-radius: 5px;
}